import "./home.component.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dialog, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import whyAutomate1 from "../../assets/images/why_automate_1.png";
import whyAutomate2 from "../../assets/images/why_automate_2.png";
import whyAutomate3 from "../../assets/images/why_automate_3.png";
import whyAutomate4 from "../../assets/images/why_automate_4.png";
import whyAutomate5 from "../../assets/images/why_automate_5.png";
import whyAutomate6 from "../../assets/images/why_automate_6.png";

import whyChoose1 from "../../assets/images/why_choose_1.png";
import whyChoose2 from "../../assets/images/why_choose_2.png";
import whyChoose3 from "../../assets/images/why_choose_3.png";
import whyChoose4 from "../../assets/images/why_choose_4.png";
import whyChoose5 from "../../assets/images/why_choose_5.png";
import whyChoose6 from "../../assets/images/why_choose_6.png";

import platformWhite1 from "../../assets/images/platform_white1.png";
import platformWhite2 from "../../assets/images/platform_white2.png";
import platformWhite3 from "../../assets/images/platform_white3.png";

import ProgressSliderComponent from "../../components/progress-slider/progress-slider.component";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ReactCardFlip from "react-card-flip";

function HomeComponent() {
  const youtubeOptions = {
    playerVars: {
      autoplay: 0,
    },
  };

  const operationsSliderSettings = {
    infinite: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: false,
    fade: true,
  };

  useEffect(() => {}, []);

  const navigate = useNavigate();

  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);

  const goToLink = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="home-component">
      <section className="section-hero-main" id="hero">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="wave-container">
              <div
                style={{ position: "relative", padding: "64px 0px 0px 0px" }}
              >
                <h1
                  className="text-center hero-heading-font home-hero-heading-font"
                  style={{ marginBottom: "0px" }}
                >
                  {/* Universal Autonomy */}
                  <div
                    style={{
                      maxWidth: "900px",
                      padding: "0px 16px",
                      display: "inline-block",
                    }}
                  >
                    Self-driving for the world's controlled spaces
                  </div>
                </h1>
                {/* <div className="row slider">
                  <div className="col-12 col-md-6 text-center text-md-end">
                    <h1 className="text-center hero-subheading-font" style={{}}>
                      One Kit Any
                      <span className="d-none d-md-inline ">&nbsp;</span>
                    </h1>
                  </div>
                  <div className="col-12 col-md-6 text-center text-md-start">
                    <h1 className="hero-subheading-font">
                      <Slider {...operationsSliderSettings}>
                        <span className="text-accent">Equipment</span>
                        <span className="text-accent">Environment</span>
                        <span className="text-accent">Application</span>
                      </Slider>
                    </h1>
                  </div>
                </div> */}

                {/* <h2 className="text-center hero-subheading-font">
                  automating mobility operations across industries
                </h2> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-hero-video">
        <video autoPlay loop muted playsInline>
          <source
            src={require("../../assets/videos/retrofit.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="section-in-action" id="in-action">
        <div className="gradient1" style={{ padding: "32px 0px" }}>
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="heading-font">See Flux in Action</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="subheading-font">
                With the Flux platform you can make your existing assets and
                infrastructure <br className="d-none d-lg-block" /> work more
                efficiently, while also optimizing costs, and prioritizing
                safety.
                <br className="d-none d-lg-block" /> Watch the tech in action
                below!
              </h2>
            </div>
            <div className="col-12">
              <ProgressSliderComponent
                data={[
                  {
                    videoSrc: require("../../assets/videos/slider_in_action1.mp4"),
                    title: "Pallet Hauling",
                    description:
                      "Automate pallet hauling operations without needing any human help",
                    youtubeId: "Gwu-BUzGtRc",
                  },
                  {
                    videoSrc: require("../../assets/videos/slider_in_action2.mp4"),
                    title: "Trailer Unloading",
                    description:
                      "Unload trailers autonomously, drop anywhere in the warehouse",
                    youtubeId: "UQcOpfrQreQ",
                  },
                  {
                    videoSrc: require("../../assets/videos/slider_in_action3.mp4"),
                    title: "Order Picking",
                    description:
                      "Automated pallet jacks for driverless order picking operations",
                    youtubeId: "zW2ZSSV-NTI",
                  },
                  {
                    videoSrc: require("../../assets/videos/slider_in_action4.mp4"),
                    title: "Farming",
                    description:
                      "Flux's solution automates tractors along with implement control",
                    youtubeId: "3Ep3oCqXWYw",
                  },
                ]}
                overlay={null}
              ></ProgressSliderComponent>
            </div>
          </div>
        </div>
      </section>

      <section className="section-flux-platform" id="flux-platform">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="heading-font">The Flux Platform</h1>
              <h2 className="subheading-font">
                The Flux Universal Autonomy Platform automates legacy machines
                with Al, <br className="d-none d-lg-block" />
                extending their lifespan and boosting efficiency.
              </h2>
            </div>
          </div>
        </div>
        <div
          className="gradient2 d-none d-lg-block"
          style={{
            height: "142px",
            position: "absolute",
            width: "100%",
            marginTop: "36px",
          }}
        ></div>

        <div
          className="gradient2 d-block d-lg-none"
          style={{
            height: "1076px",
            position: "absolute",
            width: "142px",
            left: "calc(50% - 71px)",
            marginTop: "-16px",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4">
              <ReactCardFlip isFlipped={isFlipped1}>
                <div
                  className="card-container"
                  onClick={() => setIsFlipped1(false)}
                >
                  <div className="image-container">
                    <img src={platformWhite1} />
                  </div>
                  <div className="card-title theme-font">A.R.K</div>
                  <p>Autonomous / Robotics Kit</p>
                </div>
                <div
                  className="card-details"
                  onClick={() => setIsFlipped1(false)}
                ></div>
              </ReactCardFlip>
              <br className="d-block d-lg-none" />
            </div>

            <div className="col-12 col-lg-4">
              <ReactCardFlip isFlipped={isFlipped2}>
                <div
                  className="card-container"
                  onClick={() => setIsFlipped2(false)}
                >
                  <div className="image-container">
                    <img src={platformWhite2} />
                  </div>
                  <div className="card-title theme-font">Odin</div>
                  <p>AI Platform</p>
                </div>
                <div
                  className="card-details"
                  onClick={() => setIsFlipped2(false)}
                ></div>
              </ReactCardFlip>
              <br className="d-block d-lg-none" />
            </div>

            <div className="col-12 col-lg-4">
              <ReactCardFlip isFlipped={isFlipped3}>
                <div
                  className="card-container"
                  onClick={() => setIsFlipped3(false)}
                >
                  <div className="image-container">
                    <img src={platformWhite3} />
                  </div>
                  <div className="card-title theme-font">Nucleus</div>
                  <p>User Interface</p>
                </div>
                <div
                  className="card-details"
                  onClick={() => setIsFlipped3(false)}
                ></div>
              </ReactCardFlip>
              <br className="d-block d-lg-none" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <Button
                size="large"
                style={{ marginTop: "32px" }}
                variant="contained"
                onClick={() => {
                  navigate("technology");
                }}
              >
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="section-why-automate" id="why-automate">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                Why Automate?
              </h1>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img
                          src={whyAutomate1}
                          style={{ width: "28px", height: "auto" }}
                        />
                      </span>
                      <span className="card-title">Higher Productivity</span>
                    </h2>
                    <p>
                      Operate longer hours while performing more movements each
                      day with your existing fleet. Flux needs no breaks, and no
                      time off.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyAutomate2} />
                      </span>
                      <span className="card-title">Lower Operating Costs</span>
                    </h2>
                    <p>
                      Flux enabled vehicles and equipment can operate completely
                      driverless. Use the platform to cut your daily operating
                      costs across your fleet.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyAutomate3} />
                      </span>
                      <span className="card-title">
                        Overcome Driver Shortages
                      </span>
                    </h2>
                    <p>
                      Driver shortages are being felt across industries around
                      the world. Flux Al operates vehicles operate without
                      needing human help.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyAutomate4} />
                      </span>
                      <span className="card-title">Increased Safety</span>
                    </h2>
                    <p>
                      360° sensors give the Flux system input for precise
                      decision making. Take manual control at any time with the
                      push of a button.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyAutomate5} />
                      </span>
                      <span className="card-title">Lower Operating Risks</span>
                    </h2>
                    <p>
                      Remove human operators from high risk environments like
                      warehouses or mines while simultaneously improving
                      productivity and safety.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="tile-card">
                    <h2>
                      <span className="image-container">
                        <img src={whyAutomate6} />
                      </span>
                      <span className="card-title">Infinite Scalability</span>
                    </h2>
                    <p>
                      Flux systems scale as quickly as you need, deploy new
                      locations in days. Eliminate time taken for hiring,
                      training and certifying new operators.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-simple-but-mighty" id="simple-but-mighty">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="heading-font" style={{ marginBottom: "32px" }}>
                <span>Simple but Mighty</span>
              </h1>
            </div>
            <div
              className="col-12 col-lg-10 offset-0 offset-lg-1 text-center"
              style={{ marginTop: "32px" }}
            >
              <img src={require("../../assets/images/Product Grouping.png")} />
            </div>
          </div>
        </div>
      </section>

      <section className="section-why-choose" id="why-choose">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="heading-font">Why Choose Flux?</h1>
              <h2 className="subheading-font">
                The Flux platform that makes your existing assets and
                infrastructure work harder <br className="d-none d-lg-block" />
                and more efficiently, while also optimizing costs, and
                prioritizing safety.
              </h2>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">No Hardware Changes</span>
                    </h2>
                    <p>
                      Keep your existing fleet of vehicles, and still add
                      autonomous capabilities to them with Flux's bolt-on
                      aftermarket kit.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">
                        No Infrastructure Changes
                      </span>
                    </h2>
                    <p>
                      Make no changes to your location. No tapes, stickers or
                      beacons needed to help Odin navigate or operate.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">No Process Changes</span>
                    </h2>
                    <p>
                      Odin adapts to your location, your processes and your
                      workflows. It's business as usual, powered by AI.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">Diverse Experience</span>
                    </h2>
                    <p>
                      Odin is built on experience automating operations on
                      trucks, forklifts, tractors, SUVs, hatchbacks and more.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">Hive Mind</span>
                    </h2>
                    <p>
                      Automating warehouse, farming, mining and other
                      operations, Flux AI learns from all use-cases and
                      applications.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="tile-card">
                    <div
                      className="gradient1"
                      style={{
                        height: "4px",
                        width: "100%",
                        margin: "16px 0px",
                      }}
                    ></div>
                    <h2>
                      <span className="card-title">Affordable Solution</span>
                    </h2>
                    <p>
                      Built with state of the art ML models, complex mapping and
                      path planning algorithms leading to lower kit costs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeComponent;
